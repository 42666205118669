.menu {
	position: relative;
	display: none;
	height: 100%;
	align-items: center;
	padding: 0 7vw;
	
	z-index: 900;

	color: white;
	&.is-active {
		display: flex;
	
	}

	&.alt {
		.menu {
			&__button {

				svg polyline,
				svg circle {
					stroke: black;
				}

			}
		}
		.navigate {
			color: black;
			display: none;
		}
	}

	&.is-open {
		.menu {
			&__button {
				color: white;
				border-color: white;
				svg polyline,
				svg circle {
					stroke: white;
				}
			}
			&__overlay {
				opacity: 0.9;
			}
			&__links {
				opacity: 1;
				pointer-events: auto; 
			}
		}

	}

	&.is-anim {
		.menu__button {
			transform: rotate(0deg);
		}
	}

	a {
		display: block;
	}



	&__button {
		position: absolute;
		top: calc(50% - 2.5rem);
		left: 7vw;

		pointer-events: auto;
		transform: rotate(-90deg);
		transition: transform 2s $CircEaseInOut;


		@include mobile-portrait {
			top: 7vw;
			transform: rotate(-90deg);
		}

		svg polyline,
		svg circle {
			stroke: white;
		}
		svg {
			margin: 0 auto;
			display: block;
			width: 5.0rem;
			height: 5.0rem;
			position: relative;
			// cursor: pointer;

			&.is-close {
				.open-down,
				.open-up {
					stroke-dasharray: 635; 
					stroke-dashoffset: 1905; // Fix safari issue : It doesn't like negative dash-offset
				}
				.close-up {
					stroke-dasharray: 635; // !
					stroke-dashoffset: 1270;
				}
				.close-down {
					stroke-dasharray: 635; // !
					stroke-dashoffset: 2110;
				}
			}

			&.is-open {

				.open-up {
					stroke-dasharray: 635; // !
					stroke-dashoffset: 1270;
				}

				.open-down {
					stroke-dasharray: 635; // !
					stroke-dashoffset: 1700;
				}

				.close-up,
				.close-down {
					stroke-dasharray: 635;
					stroke-dashoffset: 1905;
				}
			}
		}
	}

	&__links {
		position: absolute;
		display: flex;
		left: 29vw;
		top: 50%;
    	transform: translateY(-50%);
		opacity: 0;
		// cursor: pointer;

		transition: opacity 0.5s ease;
		@include small-desktop {
			left: 27.5vw;
		}

		@include mobile-portrait {
			flex-direction: column;
			left: 7vw;
			top: calc(5rem + 7vw + 1rem);
			transform: none;
		}
	}

	&__link {
		margin-right: 10vw;
		text-align: left;
		// overflow: hidden;
		.title--3 {
			color: rgba(255,255,255,0.5);
			transition: color 0.2s ease;
			&:hover {
				color: rgba(255,255,255,1);
			}
		}

		&.is-active {
			.title--3 {
				color: rgba(255,255,255,1);
			}
		}

		&:nth-child(2) > a {
			@include mobile-portrait {
				display: none;
			}
		}
	}

	&__sublinks {
		position: absolute;
		top: 5em;
		@extend %Regular;

	    @include mobile-portrait {
			position: relative;
			top: 0;
		}
	}

	&__sublink {
		margin: 2rem 0;
		color: rgba(255,255,255,0.5);
		font-size: 1.6rem;
		line-height: 1.6em;
		transition: color 0.2s ease;
		// overflow: hidden;
		display: inline-block;
		vertical-align: middle;
		@include mobile {
			margin: 1.5rem 0;
			font-size: 1.4rem;
		}
		@include mobile-portrait {
			font-size: 2.4rem;
		}
		.menu__subtitle {
			display: inline-block;
			position: relative;
			transition: padding 0.4s $easeToinou;

			&:after {
				content: '';
				position: absolute;
				width: 0px;
				height: 1px;
				background-color: white;
				left: 0px;
				top: 50%;
				transform: translateY(-50%);
				transition: width 0.4s $easeToinou;
			}

		}

		&:first-child{
			margin-top: 4rem;
			@include mobile {
				margin-top: 2rem;
			}
			@include mobile-portrait {
				margin-top: 1rem;
			}
		}
		&.is-active {
			color: rgba(255,255,255,1);
			.menu__nb {
				color: rgba(255,255,255,1);
			}
			.menu__subtitle {
				padding-left: 1.5em;
				&::after {
					width: 1.6rem;
					@include mobile-portrait {
						width: 2.4rem;
					}
				}
			}
		}
		// body:not(.is-touch) & {
		&:hover {
			// opacity: 1;
			color: rgba(255,255,255,1);
			.menu__nb {
				color: rgba(255,255,255,1);
			}
			.menu__subtitle {
				padding-left: 1.5em;
				&::after {
					width: 1.6rem;
					@include mobile-portrait {
						width: 2.4rem;
					}
				}
			}
			.color {
				&-red {
					color: #EF1300;
				}

				&-blue {
					color: #53E1FF;
				}

				&-pink {
					color: #EF489C;
				}

				&-green {
					 color: #1FB04C;
				}
			}
		}			
		// }

	}

	&__nb {
		margin-right: 5px;
		color: rgba(255,255,255,0.5);
	}

	&__color {
		transition: color 0.2s ease;
		// color: white;
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: black;
		z-index: -1;
		opacity: 0;

		transition: opacity 0.5s ease;
	}

	.navigate {
		position: absolute;
		color: white;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
    	letter-spacing: 2.5px;
    	@extend %Regular;
	    text-transform: uppercase;
	    font-size: 1rem;
	    opacity: 0;
	    display: none;

	}

}