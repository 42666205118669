/* ------------------------------------------------------------------ */
/*        BUTTONS
/* ------------------------------------------------------------------ */

.button {
	border: 1px solid white;
	width: 50px;
	height: 50px;
	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	// in CSS3D
	.css-container & {
		width: 60px;
    	height: 60px;
    	border: 2px solid white;
	}
}
