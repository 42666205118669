@mixin ratio($width, $height) {
	display: block;
	height: 0;
	padding: 0 0 percentage($height / $width) 0;

	position: relative;

	overflow: hidden;
}

@mixin center() {
	position: absolute;
	top: 50%;
	left: 50%;

	transform: translate(-50%, -50%);
}