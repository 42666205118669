.orientation {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	z-index: 9999;
	text-align: center;

	img {
		width: 30vw;
	}

	p {
		text-transform: uppercase;
		color: black;
		font-size: 1.6rem;
		margin-top: 5rem;
		@extend %Light;
		white-space: nowrap;
		letter-spacing: 0.1em;
	}

	@include mobile-portrait {
		// display: block;
	}

}