.webGL  {
    position: relative;
    color: $color-white;

    .css-container {
        // default css container settings
        font-size: 28px;
        user-select: none;
    }

    &__canvas {
    	background-size: cover;

    	&[data-index="0"]{
    		background-image: url(../images/textures/project-0.jpg); 
    	}
       	&[data-index="1"] {
    		background-image: url(../images/textures/project-1.jpg); 
    	}
    	&[data-index="2"] {
    		background-image: url(../images/textures/project-2.jpg); 
    	}
    	&[data-index="3"] {
    		background-image: url(../images/textures/project-3.jpg); 
    	}

    	.about & {
    		background-image: none !important;
    		background-color: #E7EFFC !important;
    	}
    }
}


