.overlay {
	position: absolute;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	background-color: white;
	opacity: 0;
	z-index: 9000;
	pointer-events: none;
	transition: opacity 0.4s linear;

	&.is-intro {
		transition: opacity 1s linear;
	}

	&.is-about {
		// background-color: white;
		transition: opacity 0.5s linear;
	}

	&.visible {
		opacity: 1;

	}

	&.black {
		background-color: black;
	}
}