&.is-about {
	color: $color-black;
	line-height: 1.4em;
	z-index: 10;
	text-align: left;
	user-select: none;
	font-size: 1.2rem;
	
	// height: 100%;
	left: 50%;
	transform: translate3D(-50%, 0, 0);
	// max-width: 1000px;
	position: absolute;
	width: 64vw;

	@include mobile {
		width: 70vw;
	}

	@include mobile-portrait {
		width: 86vw;
	}

	p, li {
		font-size: 1.4rem;
		line-height: 2em;
		color: black;
		@include mobile {
			line-height: 1.6em;
		}
	}
	.socials {

		li {
			display: inline-block;
			color: black;
			margin-right: 2em;
			line-height: 1.4em;
		}

		a:not(.about__work__top) span {
			bottom: 0px;
		}
	}
	.strong {
		@extend %Medium;
	}

	a:not(.about__work__top) {
        opacity: 1;
        position: relative;
        display: inline-block;
		color: black;
		pointer-events: auto;

        span {
            position: absolute;
            bottom: 4px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: black;
            pointer-events: none;
        }
    }

    .about {
		&__intro {
			width: 64.0rem;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate3D(0, -50%, 0);
			display: none;

			@include mobile {
				width: 100%;
			}

			p {
				overflow: hidden;
				position: relative;
				margin-bottom: 1.6rem;
				& > * {
					transform: translate3D(0, 130%, 0);
					opacity: 0;
					
					display: inline-block;
					vertical-align: top;
					will-change: transform;
					.ff & {
						transform: translate3D(0, 0, 0);
					}
					&.is-anim {
						opacity: 1;
						transform: translate3D(0, 0, 0);
						transition: opacity 1s linear, transform 1.6s $ExpoEaseOut;
						
					}
				}
			}
		}

		&__title {
			font-size: 3.4em;
			// line-height: 1.8em;
			display: inline-block;
			overflow: hidden;
			line-height: 1;
			margin-bottom: 1.6rem;
			& > * {
				transform: translate3D(0, 100%, 0);
				opacity: 0;
				vertical-align: top;
				.ff & {
					transform: translate3D(0, 0, 0);
				}
				&.is-anim {
					opacity: 1;
					transform: translate3D(0, 0, 0);
					transition: opacity 1s linear, transform 2s $ExpoEaseOut;
				}
			}
			
		}
	    &__more,
	    &__back {
	    	display: inline-block;
	    	font-size: 0.8em;
	    	left: 0px;
	    	padding-left: 0px;
	    	margin: 20px 0;
	    	letter-spacing: 2px;
	    	@extend %Regular;
			opacity: 1;
			pointer-events: auto;
			position: relative;

			.line {
				position: absolute;
				width: 0px;
				height: 1px;
				background-color: black;
				left: 0px;
				top: 42%;
				.is-touch & {
					width: 15px;
				}
			}

			.is-touch & {
				padding-left: 25px;
			}
	    }

	    &__more {
	    	transform: translateY(100%);
			opacity: 0;
			transition: opacity 1s linear, transform 2s $ExpoEaseOut;
			overflow: hidden;
			display: inline-block;

			&.is-anim {
				opacity: 1;
				transform: translateY(0%);
			}
	    }

	    &__back {
	    	margin-bottom: 36px;
	    	margin-top: 0px;
	    }

	    &__works {
	    	position: absolute;
	    	top: 5rem;
	    	left: 50%;
	    	transform: translate3D(-50%, 0, 0);
			width: 100%;
			display: none;
			pointer-events: auto;

			&.noscroll {
		    	top: 50%;
		    	left: 50%;
		    	transform: translate3D(-50%, -50%, 0);	
			}

	    }

	    &__work {

	    	margin-bottom: 3.6rem;
			
			&__top {
				color: black;
		    	display: flex;
		    	align-items: center;
		    	justify-content: space-between;		
		    	font-size: 2em;
		    	line-height: 2rem;
				@include mobile {
					font-size: 1.7em;
					line-height: 1em;
				}

				& > span:first-child {
					overflow: hidden;
					line-height: 1.2;
					& > * {
						transform: translate3D(0, 150%, 0);
						opacity: 0;
						
						will-change: transform;
						overflow: hidden;
						display: inline-block;
						vertical-align: top;
						.ff & {
							transform: translate3D(0, 0, 0);
						}
						&.is-anim {
							opacity: 1;
							transition: opacity 0.6s linear, transform 2s $ExpoEaseOut;
							transform: translate3D(0, 0, 0);
						}
					}
				}
			}

			&__descr {
				line-height: 1;
				& > * {
					transform: translate3D(0, 100%, 0);
					opacity: 0;
					
					will-change: transform;
					display: inline-block;
					vertical-align: top;
					.ff & {
						transform: translate3D(0, 0, 0);
					}
					&.is-anim {
						opacity: 1;
						transform: translate3D(0, 0, 0);
						transition: opacity 1s linear, transform 1.6s $ExpoEaseOut;
					}
				}
			}

			p {
				font-size: 1.4rem;
				@extend %Regular;
				width: 80%;
				line-height: 1.6em;

			}

			// padding: 0 20vw;
	    	span {
	    		margin-right: 20px;

	    		.is-touch & {
	    			margin-right: 0px;
	    		}
	    	}

	    	.about__button {
		    	display: flex;
		    	align-items: center;
				opacity: 0;
				transition: opacity 1.6s linear;
	    		svg {
				 	width: 4.5rem;
					height: 4.5rem;
					position: relative;

					polyline,
					circle {
						stroke: black;
					}
	    			.open-down,
					.open-up {
						stroke-dasharray: 635;
						stroke-dashoffset: 3175; // Fix safari issue : It doesn't like negative dash-offset
					}
					.close-up {
						stroke-dasharray: 635; // !
						stroke-dashoffset: 1700;
					}
					.close-down {
						stroke-dasharray: 635; // !
						stroke-dashoffset: 2360;
					}
					.close-down-2 {
						stroke-dasharray: 635; // !
						stroke-dashoffset: 3075;
					}
	    		}

				&.is-anim {
					opacity: 1;
				}
	    	}
	    }
    }

}