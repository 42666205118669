.footer {
	position: absolute;
	left: 7vw;
	bottom: 5rem;
	z-index: 100;

	@include tablet {
		bottom: 2rem;
	}

	@include mobile-portrait {
		left: 95vw;
   		top: 19vw;
   		bottom: auto;
	    transform: rotate(180deg);
	    transform-origin: 0% 0%;
	    display: inline-block;

	}

	&.content-open {
		// @include mobile-portrait {
		// 	background: linear-gradient(to top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 93%,rgba(0,0,0,0) 100%);
		// }

		// @include tablet {
		// 	background: linear-gradient(to top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 93%,rgba(0,0,0,0) 100%);
		// }
	}

	ul {
		display: flex;
		align-items: center;
		line-height: 1;

		@include mobile-portrait {
			justify-content: space-around;
			height: 7rem;
		}

		@include tablet {
			justify-content: space-around;
			height: 7rem;
		}

		li {
			display: inline-block;
			vertical-align: middle;
			
			pointer-events: auto;
			margin: 0 1.5rem;
			
	    	letter-spacing: 1px;
			@extend %Regular;
	    	font-size: 1.4rem;

	    	&:first-child {
	    		margin-left: 0px;
	    	}

			@include tablet {
				margin: 0 1rem;
			}

			@include mobile-portrait {
				font-size: 1.5rem;
			}

			&.sound {
				position: relative;
				overflow: hidden;
				width: 2rem;
				height: 2rem;
				opacity: 0.5;
				transition: opacity 0.5s ease;

				@include mobile-portrait {
					transform: rotate(180deg);
				}
				.is-touch & {
					opacity: 1;
					width: 4rem;
					height: 4rem;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				svg {
				    width: 17rem;
				    stroke-width: 8;
				    height: 5rem;
				    fill: none;
				    stroke: black;

					.is-touch & {
						width: 26rem;
						height: 3rem;
					}
				}

				.touch__container {

					.is-touch & {
						position: relative;
						width: 2.5rem;
						height: 2.5rem;
						overflow: hidden;
					}

					span {
						width: 17rem;
						position: absolute;
						left: 0%;
						display: flex;
						align-items: center;
						height: 100%;
						transition: left 1.5s $Power1EaseInOut;
						background: url(../images/icons/wave-01.svg);
						z-index: 1;
						pointer-events: auto;

						.is-touch & {
							width: 21rem;
						}
					}

				}

				&.off .touch__container span {
					left: -480%;
					transition: left 1.5s $Power1EaseOut;
				}

				body:not(.is-touch) & {
					&:hover {
						opacity: 1;
					}
				}
			}

			&.plus {
				display: flex;
				align-items: center;
				svg {
					height: 1.3rem;
					width: 1.3rem;
					@include small-desktop {
						height: 1rem;
						width: 1rem;
					}

					.is-touch & {
						width: 1.2rem;
						height: 1.2rem;

					}
				}

				&.is-hide {
					opacity: 0;
				}
				.is-touch & {
					margin: 0;
				}
			}

			.plus__icon {
				width: 3rem;
				height: 3rem;
				margin-left: -1.2rem;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				// background-color: green;
				margin-right: 1rem;
				opacity: 0.5;
				transition: transform 1s $ExpoEaseOut, opacity 0.5s ease;

				body:not(.is-touch) & {
					&:hover {
						opacity: 1;
					}
				}

				line {
					stroke: black;
					stroke-width: 4;
					opacity: 1;
				}

				.is-touch & {
					opacity: 1;
					width: 4rem;
					height: 4rem;
					justify-content: center;
					margin-right: 0;

				}

				.touch__container {
					position: relative;
					display: flex;
					transition: transform 1s $ExpoEaseOut;
					.is-touch & {
						position: relative;
						display: flex;
					}
				}

				@include mobile-portrait {
					display: flex;
					height: 2.2rem;
				}
			}

			.socials {
				height: 2rem;
				display: inline-flex;
				align-items: center;
				vertical-align: middle;
				pointer-events: auto;
				margin: 0 0.5rem;
		    	letter-spacing: 1px;
		    	font-size: 1.4rem;
				opacity: 0;
				transition: transform 1s $ExpoEaseOut, opacity 0.2s linear;
				transform: translateX(-100%);

				&:nth-child(2) {
					margin-left: 0.4rem;
				}

				a {
					color: black;

					@include mobile-portrait {
						transform: rotate(180deg);
					}
					.is-touch & {
						line-height: 1.5em;
					}
				}
				
				.is-touch & {
					font-size: 1.6rem;
					height: 2.2rem;

				}
				body:not(.is-touch) & {
					&:hover {
						opacity: 1 !important;
					}
				}

			}
			svg {
				width: 2rem;
				fill: black;
				@include tablet {
					width: 2.1rem;
				}

			}

			body:not(.is-touch) & {
				&:hover {
					.plus__icon .touch__container {
						transform: rotate(135deg);
					}
					.socials {
						transform: translateX(0%);
						opacity: 0.5;
					}
					
				}
			}

			.is-touch & {
				.socials {
					transform: translateX(-80%);
					opacity: 0;
					pointer-events: none;
				}
				&.is-open {
					.plus__icon {
						transform: rotate(135deg);
					}
					.socials {
						transform: translateX(0%);
						opacity: 1;
						// pointer-events: auto;
					}
				}
			}



		}
	}

}

.is-project + .footer ul li svg {
	fill: white;

	path,
	line {
		stroke: white;
	}
}

.is-project + .footer ul li.sound svg {
	fill: none;
	stroke: white;
}

.is-project + .footer .socials a {
	color: white;
}

// .is-about + .footer .socials {
// 	@include tablet {
// 		display: none;
// 	}
// 	@include mobile-portrait {
// 		display: flex;
// 	}
// }
