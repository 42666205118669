.glitch {
	display: none;
	position: absolute;
	// background-color: #343434;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 900;
	text-align: center;

	@extend %Regular;

	// background-image: url('../images/textures/glitch-1.png');
	background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
	background-size: 1800% 1800%;

	-webkit-animation: rainbow 18s ease infinite;
	-z-animation: rainbow 18s ease infinite;
	-o-animation: rainbow 18s ease infinite;
	  animation: rainbow 18s ease infinite;


	&__canvas {
		box-sizing: border-box;
		text-align: center;
		z-index: 9000;
		position: relative;

		&-buffer {
			display: none;
		}

		&-alpha-buffer {
			display: none;
		}

	}

	&__img {
		height: 100px;
		width: auto;
	}
	img {
		display: none;
	}

	.project__title & {
		background: none;
		opacity: 0;

		&__canvas,
		&__canvas-buffer {
			z-index: 9000;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			height: 420px;
			top: 3%;

			@include mobile {
				height: 650px;
			}

		}
	}

	.android & { // fix weird crop issue on Android devices
		transform: scale(2.8);
		@include mobile {
			transform: scale(4.3);
		}
		&__canvas,
		&__canvas-buffer {
			height: auto;

			@include mobile {
				height: auto;
			}

		}
	}
}

// img {
//   display: none;
// }
// @-webkit-keyframes rainbow {
//     0%{background-position:0% 82%}
//     50%{background-position:100% 19%}
//     100%{background-position:0% 82%}
// }
// @-moz-keyframes rainbow {
//     0%{background-position:0% 82%}
//     50%{background-position:100% 19%}
//     100%{background-position:0% 82%}
// }
// @-o-keyframes rainbow {
//     0%{background-position:0% 82%}
//     50%{background-position:100% 19%}
//     100%{background-position:0% 82%}
// }
// @keyframes rainbow { 
//     0%{background-position:0% 82%}
//     50%{background-position:100% 19%}
//     100%{background-position:0% 82%}
// }

