.project {

		&__title {
				font-size: 488%;
				display: inline-block;
				vertical-align: middle;
				white-space: nowrap;
				pointer-events: auto;
				position: relative;
				z-index: 9000;
				transform: translateX(50%); // align with other title
				

				@include mobile {
					font-size: 758%;
				}

				h1 {
					opacity: 0;
					visibility: hidden;
					display: inline-block;
					width: 300%;
				}
				&:hover,
				.is-touch &  {
					.project__more {
						padding-left: 100px;
					}
					.project__more::after {
						width: 60px;
					}
				}

		}

		&__number, &__more {
			position: absolute;
			color: white;
			// display: none;
			opacity: 0;
		}

		&__number {
			position: absolute;
			top: -300px;
			font-size: 55px;
			left: 5px;
			@include mobile {
				font-size: 78px;
			}
		}

		&__more {
			left: 0px;
			padding-left: 0px;
			font-size: 44px; // in px because resize relative to the scene
			letter-spacing: 4px;
			@extend %Regular;
			top: 170px;
			opacity: 1;
			transition: padding 0.8s $easeToinou;

			@include mobile {
				top: 230px;
				font-size: 70px;
			}

			&:after {
				content: '';
				position: absolute;
				width: 0px;
				height: 3px;
				background-color: white;
				left: 0px;
				top: 50%;
				transform: translateY(-50%);
				transition: width 0.8s $easeToinou;
				@include mobile {
					height: 7px;
				}
			}

			span {
				display: none;
				@include mobile {
					display: inline;
				}
			}
			// font-size: 2em;

		}

}