.preload {
	position: fixed;
	top: 0;
	left: 0;
	background-color: white;
	width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9000;
	overflow: hidden;
	pointer-events: none;

	&__wrapper {
		position: relative;
		display: flex;
		align-items: center;
		height: 6.0rem;
		opacity: 0;

		.is-touch & {
			pointer-events: auto;
		}

		&.start-fs {
			pointer-events: auto;
			font-size: 1.6rem;
	    	letter-spacing: 0.2em;
	    	text-transform: uppercase;
	    	@extend %Regular;
		}

	}

	&__glitch {
		height: 100%;
		display: inline-block !important;
	}

	&__txt {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
		font-size: 1.2rem;
		text-transform: uppercase;
    	letter-spacing: 0.2em;
    	margin-top: 2.5rem;
    	@extend %Regular;
	    opacity: 0;

	    display: none;

	    .is-touch & {
	    	display: block;
	    }
	}

	&__symbol {
		color: black;
		font-size: 2rem;
		margin-right: 3.5rem;
		pointer-events: none;
		height: 100%;
		text-transform: uppercase;
		display: flex;
    	align-items: center;
    	position: relative;

		&.is-center {
			pointer-events: auto;
		}

		p {
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			font-size: 1.4rem;
	    	letter-spacing: 4px;
	    	margin-top: 15px;
	    	@extend %Regular;
		    opacity: 0;
		    will-change: transform;
		    margin-left: 0.3rem;
		}

		svg polyline,
		svg circle {
			stroke: black;
		}

		svg circle {
			stroke-dasharray: 307%;
			stroke-dashoffset: 307%;
		}

		svg {
			margin: 0 auto;
			display: block;
			width: 5.0rem;
			height: 5.0rem;
			position: relative;
			transform: rotate(-90deg);
			// cursor: pointer;
			.close-up {
				stroke-dasharray: 635; // !
				stroke-dashoffset: 1270;
			}
			.close-down {
				stroke-dasharray: 635; // !
				stroke-dashoffset: 2110;
			}

		}
	}

}