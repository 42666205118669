html {
	font-family: 'Theinhardt';
    line-height:$base-line-height;
    font-size: 12px; // > 1920
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@include desktop { // 1900
		font-size: 12px;
	}
	@include small-desktop { // 1440 --> BASE
		font-size: 10px;
	}
	@include tablet { // 
		font-size: 9px;
	}
	@include mobile {
		font-size: 8px;
		width: 100%;
		height: 100%;
		pointer-events: none;
		// position: fixed;
		// top: 0;
		// left: 0;
		// overflow: hidden;
	}

}

body {
	@include mobile {
		width: 100%;
		height: 100%;
		// position: fixed;
		top: 0;
		left: 0;
		pointer-events: none;
		z-index: 2000;
	}
	background-color: white;
	overflow: hidden;

	&.is-init {
	    cursor:none !important;
	    a {
	    	cursor: none !important;
	    }	
	}

}

.dg.ac {
	z-index: 9000 !important;
}

.debug {
	position: absolute;
	top: 0;
	right: 0;
	color: black;
	font-size: 16px;
	z-index: 90000;
}