.ui {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	z-index: 100;

	@import "ui/menu";
	@import "ui/footer";

	&-content {
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		@import "ui/intro";
		@import "ui/about";
		@import "ui/project";

	}

}