.color {
	&-red {
		color: #EF1300;
	}

	&-blue {
		color: #53E1FF;
	}

	&-pink {
		color: #EF489C;
	}

	&-green {
		 color: #1FB04C;
	}
}