/* ------------------------------------------------------------------ */
/*        TITLE DECLARATION
/* ------------------------------------------------------------------ */

.title {
    // @extend %smooth; // Optionnal
    // @extend %Regular;

    // Variants, can be .title--1 or .title--main
    // if your prefer order your titles by slugs

    &--1 {
		font-size: 2.6rem;
		line-height: 1.6em;
		letter-spacing: 0.5em;
		text-transform: uppercase;
    }

    &--2 {
    	// font-family: 'Theinhardt-ita';
		font-size: 1.2rem;
		line-height: 1.6em;
		letter-spacing: 0.3em;
    }

    &--3 {
		font-size: 4.8rem;
		line-height: 1.6em;
		@include mobile {
			font-size: 3.6rem;
		}
		@include mobile-portrait {
			font-size: 6rem;
		}
    }

    // Only in WebGL 

    &--4 {
		font-size: 2em;
		line-height: 1.6em;
		// font-style: italic;
    }
}
