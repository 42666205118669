$project3D-w : 1075px;

&.is-project {
	color: $color-white;
	line-height: 1.4em;
	z-index: 10;
	text-align: left;
	user-select: none;
	font-size: 1.5rem;
	letter-spacing: 0.06rem;
	.project {

		&.alt {
			color: $color-black;
			.icon {
				fill: $color-black;
			}
		}

		.icon {
			fill: $color-white;
		}

		&__top {
			opacity: 0;
			transform: translateY(80px);
			transition: opacity 0.6s linear,  transform 1.2s $ExpoEaseOut;

			&.is-anim {
				opacity: 1;
				transform: translateY(0px);	
			}
		}

		&__container {
			width: 100%;
			position: absolute;
			top: 35%;
			pointer-events: auto;
			line-height: 1.4em;
			z-index: 10;
			text-align: left;
			user-select: none;
			opacity: 0;
			display: none;
			width: 64vw;
			// height: 100%;
			left: 50%;
			transform: translateX(-50%);
			max-width: 1300px;

			@include mobile-portrait {
				width: 86vw;
				left: 7vw;
				transform: none;
			}
		}

		&__header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			margin-bottom: 2.0rem;
			overflow: hidden;

			.title--4 {
				overflow: hidden;
				line-height: 1;
				& > * {
					opacity: 0;
					transform: translate3D(0, 100%, 0);
					transition:  opacity 0.8s linear, transform 1.8s $ExpoEaseOut;
					.ff & {
						transform: translate3D(0, 0, 0);
					}
					&.is-anim {
						opacity: 1;
						transition: opacity 0.8s linear, transform 1.8s $ExpoEaseOut;
						transform: translate3D(0, 0, 0);
					}
				}
			}

		}

		&__date {
			color: $color-grey;
			transform: translate3D(0, 100%, 0);
			opacity: 0;
			.ff & {
				transform: translate3D(0, 0, 0);
			}
			&.is-anim {
				opacity: 1;
				transition: opacity 0.8s linear, transform 1.8s $ExpoEaseOut;
				transform: translate3D(0, 0, 0);
			}
		}

		&__descr {
			width: 100%;
			line-height: 2em;
			@extend %Regular;
			transform: translateY(80px);
			opacity: 0;
			transition: opacity 0.6s linear,  transform 1.5s $ExpoEaseOut;
			letter-spacing: 0;

			&.is-anim {
				transform: translateY(0px);
				opacity: 1;
			}
		}

		&__subHeader {
			width: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-top: 3.0rem;

			@extend %Regular;
			overflow: hidden;

			transform: translateY(80px);
			opacity: 0;
			transition: opacity 0.6s linear,  transform 1.5s $ExpoEaseOut;

			&.is-anim {
				transform: translateY(0px);
				opacity: 1;
			}

			@include mobile-portrait {
				display: block;
			}
		}

		&__role,
		&__technos,
		&__context {
			width: 20%;

			p:first-child {
				margin-bottom: 1.0rem;
			}

			p:last-child {
				color: $color-grey;
				white-space: nowrap;
			}

			@include mobile-portrait {
				width: 30%;
				margin-top: 3rem;
			}

			transform: translateY(90%);
			transition: transform 1.5s $ExpoEaseOut;

			&.is-anim {
				transform: translateY(0%);
			}
		}

		&__link {
			width: 40%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			@include mobile-portrait {
				width: auto;
				margin-top: 3rem;
				justify-content: space-between;
				transform: translateY(90%);
				transition: transform 1.5s $ExpoEaseOut;
			}

			&.is-anim {
				@include mobile-portrait {
					transform: translateY(0%);
				}
			}

			span {
				margin-right: 2.0rem;
			}

			a {

				svg {
					width: 4.5rem;
					height: 4.5rem;
					position: relative;

					polyline,
					circle {
						stroke: white;
					}
					.open-down,
					.open-up {
						stroke-dasharray: 635;
						stroke-dashoffset: 3175; // Fix safari issue : It doesn't like negative dash-offset
					}
					.close-up {
						stroke-dasharray: 635; // !
						stroke-dashoffset: 1700;
					}
					.close-down {
						stroke-dasharray: 635; // !
						stroke-dashoffset: 2360;
					}
					.close-down-2 {
						stroke-dasharray: 635; // !
						stroke-dashoffset: 3075;
					}
				}

			}
		}

		&__gallery {
			margin-top: 10.0rem;
			margin-bottom: 6.0rem;
			position: relative;
			width: 100%;

			@include mobile {
				margin-top: 3.0rem;
				margin-bottom: 3.0rem;
			}
		}

		&__image {	        

			img {
				width: 100%;
				border: 1px solid rgba(128, 128, 128, 0.15);
				transform-origin: 0% 0%;
			}
		}

		&__video {

			video {
				width: 100%;
			}
		}

		&__item {
			width: 100%;
			visibility: hidden;
			margin-bottom: 7.0rem;
			user-select: none;
			opacity: 0;
			transition: opacity 0.6s linear,  transform 1.3s $ExpoEaseOut;
			transform: scaleY(2.2) translateY(80px);
			transform-origin: 0% 0%;

			@include mobile {
				transition: opacity 1s linear,  transform 1.6s $ExpoEaseOut;
				margin-bottom: 2.0rem;
			}

			&:first-child {
				transition: opacity 0.6s linear,  transform 1.5s $ExpoEaseOut;
			}
			

			&.is-anim {
				transform: scaleY(1) translateY(0px);
				opacity: 1;
			}
		}

		&__btn {
			// cursor: pointer;
		}

		&__footer {
			opacity: 0;
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			font-size: 1em;
			text-align: left;
			width: 100%;
			// transform: translateY(50%);
		}

		&__awards {
			color: $color-grey;
		}

		&__back {
			display: none;
			height: 40px;
			padding: 0 2vw;
			opacity: 0;
			visibility: hidden;

			align-items: center;
			justify-content: center;

			position: absolute;
			top: calc(33% - 2px);
			left: 5vw;
			pointer-events: auto;
			opacity: 0;
			transform: translateY(80px);
			transition: opacity 0.6s linear,  transform 1.2s $ExpoEaseOut;

			&.is-anim {
				opacity: 1;
				transform: translateY(0px);	
			}

			@include mobile-portrait {
				height: 24vw;
				width: 100%;
				top: 0px;
				padding: 0;
				left: 0;
				transform: translateY(0px);
				background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 95%,rgba(0,0,0,0) 100%);
				justify-content: flex-start;
				z-index: 20;
				pointer-events: none;
			}

			p {
				padding-left: 22px;
				margin-right: -1.5px;

				position: relative;

				text-transform: uppercase;
				letter-spacing: 1.5px;
				@extend %Regular;
				font-size: 1.5rem;
				line-height: 40px;

				pointer-events: auto;

				@include mobile-portrait {
					margin-left: 7vw;
					margin-right: 7vw;
					padding-left: 25px;
					font-size: 1.5rem;
					pointer-events: auto;
				}

				&::after {
					content: '';
					position: absolute;
					width: 15px;
					height: 1px;
					background-color: white;
					left: 0px;
					top: 50%;
					transform: translateY(-50%);

					@include mobile-portrait {
						width: 18px;
					}
				}
			}


			.is-touch & {
				display: flex;
			}
		}


		.link {
			img {
				width: 4.0rem;
				margin-left: 20px;
				margin-bottom: -5px;
			}
		}


	}

	.scroll {
		position: fixed;
		color: white;
		bottom: 5rem;
		left: 50%;
		transform: translateX(-50%);
		letter-spacing: 2.5px;
		@extend %Regular;
		text-transform: uppercase;
		font-size: 1rem;
		opacity: 0;
		text-align: center;

		@include mobile-portrait {
			bottom: 15rem;
		}

		.scrolled & {
			display: none;
		}

	}
}