/* ------------------------------------------------------------------ */
/*        FONT FACE DECLARATION
/* ------------------------------------------------------------------ */

@font-face {
    font-family: 'Theinhardt';
    src: url('../fonts/theinhardt.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Theinhardt-medium';
    src: url('../fonts/theinhardt_medium.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Theinhardt-light';
    src: url('../fonts/theinhardt_light.otf');
    font-weight: 300;
    font-style: normal;
}


%Regular {
	font-family: 'Theinhardt';
	font-weight: normal;

}

%Medium {
	font-family: 'Theinhardt-medium';
	font-weight: normal;

}

%Light {
	font-family: 'Theinhardt-light';
    font-weight: 500;
}
