&.is-intro {
	display: none;
	text-transform: uppercase;
	
	& > div:first-child {
		z-index: 1;
	}
	.start {
		position: absolute;
		top: calc(50% - 2.5rem);
		left: calc(50% - 2.5rem);
		color: black;
		font-size: 2rem;
		pointer-events: auto;
		// display: none;
		cursor: pointer;
		letter-spacing: 0.4em;
		visibility: hidden;

		p {
			// position: absolute;
			font-size: 1.4rem;
	    	letter-spacing: 4px;
	    	margin-right: -4px;
	    	margin-top: 15px;
	    	@extend %Regular;
		    opacity: 0;
		}
		svg polyline,
		svg circle {
			stroke: red;
		}
		svg {
			margin: 0 auto;
			display: block;
			width: 5.0rem;
			height: 5.0rem;
			position: relative;
			transform: rotate(-90deg);
			// cursor: pointer;
			.open-down,
			.open-up {
				stroke-dasharray: 635;
				stroke-dashoffset: 635;
			}
			.close-up {
				stroke-dasharray: 635; // !
				stroke-dashoffset: 0;
			}
			.close-down {
				stroke-dasharray: 635; // !
				stroke-dashoffset: 840;
			}

		}
	}

	.intro__overlay {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		background-color: white;
		opacity: 0;
		z-index: 0;


	}
}