/* ------------------------------------------------------------------ */
/*        YOUR PROJECT NAME
/* ------------------------------------------------------------------ */

// Import core
@import "core/import";

// Add the layout dependencies here
@import "layouts/body";
@import "layouts/overlay";
// @import "layouts/header";
// @import "layouts/footer";

// Typography
@import "typography/font-face";
@import "typography/titles";

// Add your components here
@import "components/buttons";
@import "components/xp";
@import "components/webGL";
@import "components/glitch";
@import "components/cursor";
@import "components/color";
@import "components/preload";
@import "components/orientation";

// View 3D
@import "views/project";

// UI
@import "ui/ui";

a {
	text-decoration: none;
	color: white;
}