.cursor {
	position: relative;
	font-size: 1.6rem;

	&__wrapper {
		margin: 0 auto;
		display: block;
		width: 5.0rem;
		height: 5.0rem;
		position: absolute;
		transform: translate(-50%,-50%);
		z-index: 9000;
		pointer-events: none;
		font-size: 0.8rem;
		text-transform: uppercase;
		text-align: center;
		color: white;
		letter-spacing: 2.5px;
	    @extend %Medium;

	    &.alt circle {
			stroke: black;
		}

		&.menu-open circle {
			stroke: white;
		}
	}

	&__next, &__prev {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		opacity: 0;
	}
	&__next {
		top: 100%;
		margin-top: 0.7rem;
		margin-left: 0.16rem;
	}
	&__prev {
		bottom: 100%;
		margin-bottom: 0.7rem;
		margin-left: 0.16rem;
		// opacity: 1;
	}

	.c-2 {
		stroke-dasharray: 308%; // 848;
		stroke-dashoffset: 308%; // 713;
		transform: rotate(-90deg);
		transform-origin: 50% 50%;

	}

	text {
		position: absolute;
		// top: 50%;
		// left: 50%;
		color: white;
		fill: white;
		// stroke: white;
		// transform: translate(-50%,-50%);
		text-transform: uppercase;
		@extend %Medium;
	    letter-spacing: 10rem;
		opacity: 0;
		font-size: 1.8rem;
	}

	.up-1,
	.up-2,
	.down-1,
	.down-2 {
		opacity: 0;
		
	}

	.up-1,
	.up-2 {
		transform: scale(0.8) translate(10px,10px);
	}

	.down-1,
	.down-2 {
		transform: scale(0.8) translate(14px,10px);
	}

	// next / prev
	.up-1 {
		stroke-dasharray: 100%; // 848;
		stroke-dashoffset: 292%; // 713;
	}
	.up-2 {
		// display: none;
		stroke-dasharray: 100%; // 848;
		stroke-dashoffset: 186%; //  -224
	}
	.down-1 {
		stroke-dasharray: 100%;
		stroke-dashoffset: 470%;
	}
	.down-2 {
		stroke-dasharray: 100%;
		stroke-dashoffset: 364%;
	}

	polyline {
		stroke: white;
	}
	circle {
		stroke: white;
		stroke-width: 2;
	}

}